.event-card {
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
}
ul {
  display: table;
  margin: 0 auto;
}
.event-icon-list{
    li {
      list-style-type: none;
      display: inline-flex;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      border-left: 1px solid #333;
      padding: 0 32px;
      &:first-child {
        border-left: none;
        margin-right: auto;
      }
      &:last-child {
        margin-right: auto;
      }
      &:hover {
        color: #12DFF3;
      }
      span {
        text-transform: uppercase;
        line-height: 1;
        display: block;
        font-weight: 700;
      }
      i,.icon {
        font-size: x-large;
        margin-bottom: 6px;
      }
    }
}