.event-box {
    display: flex;
    flex-direction: column;
    transition: all 0.4s ease-in-out;
    background: #fff center top;
    background-blend-mode: luminosity;
    background-size: cover;
    flex-shrink: 0;
    max-width: 900px;
    width: 100%;
    min-height: 310px;
    color: #0a0c11 !important;
    border-radius: 3px;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
    position: relative;

    &:hover {
        transform: translateY(-3px) translateZ(0);
        transition-delay: 10ms;
    }

    .category {
        font: 700 0.75em/27px "Montserrat", sans-serif;
        background-color: #12DFF3;
        display: inline-block;
        width: fit-content;
        line-height: 1;
        margin: 0 2px;
        padding: 6px 8px;
        &:hover {
            transform: translateY(-3px) translateZ(0);
            transition-delay: 0.3s;

        }
    }
}
.event-box {
    &:after {
        transition: opacity 0.4s ease-in-out;
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(105, 49, 249, 0.1);
        opacity: 0;
        z-index: 1;
    }
    .event-body {
        z-index: 2;
        display: flex;
        flex-direction: column;
        padding: 28px;
        .event-name {
            color: #636f7d;
            font: 500 1.125em/1 "Sofia Pro", sans-serif;
            margin-bottom: 6px;
        }
        .date {
            text-transform: uppercase;
            margin-bottom: 18px;
            font: 700 0.875em "Sofia Pro", sans-serif;
        }
    }
    .event-footer {
        z-index: 2;
        display: flex;
        align-items: flex-end;
        margin-top: auto;
        .col {
            display: flex;
            text-transform: uppercase;
            .col-item {
                &:not(:first-of-type) {
                    margin-left: 28px;
                }
            }
            h5 {
                font: 600 0.75em/1 "Sofia Pro", sans-serif;
                color: #636f7d;
                margin-bottom: 4px;
            }
            p {
                font: 600 0.875em/1 "Sofia Pro", sans-serif;
            }
            &:not(.align-right) {
                margin-left: 28px;
                margin-bottom: 20px;
            }
        }
        .btn-default {
            min-width: 165px;
            height: 52px;
            background-color: #12DFF3;
            border-color: #12DFF3;
            border-radius: 0;
            transition: all 0.4s ease-in-out;
            font: 700 0.75em/27px "Montserrat", sans-serif;
            &:hover {
                box-shadow: -20px 0 0px 0px rgba(18, 223, 243, 0.4)
            }
        }
    }
}
@media screen and (max-width: 1268px) {
    .event-box {
        &:not(:last-child) {
            margin-bottom: 25px;
        }
    }
}
@media screen and (max-width: 769px) {
    .event-box {
        max-width: 400px;
        width: 100%;
    }
}
@media screen and (max-width: 768px) {
    .event-box {
        .event-body {
            align-items: center;
            text-align: center;
        }
        .event-footer {
            align-items: center;
            flex-direction: column;
            padding-bottom: 28px;
            .col.align-right {
                margin-left: initial;
            }
        }
    }
}

