section {
    display: block;
    flex-shrink: 0;
    padding-top: 40px;
    padding-bottom: 50px;
    box-sizing: border-box;
}
.section-header {
    flex-direction: column;
    align-items: center;
}
.section-title {
    margin-bottom: 26px;
    h2 {
        color: #0a0c11;
        margin-bottom: 10px;
    }
    h2.mb {
        margin-bottom: 16px;
    }
}
.section-title.normal {
    margin-bottom: 0;
}
@media screen and (min-width: 375px) {
    .section-header {
        margin-bottom: 40px;
        border-bottom: 1px solid rgba(10, 12, 17, 0.14);
    }
}
@media screen and (max-width: 375px) {
    .section-header {
        border-bottom: 0;
        margin-bottom: 15px;
    }
}
@media screen and (max-width: 400px) {
    .section-title {
        text-align: center;
    }
}
