.footer {
  padding:  15px 15px 5px 15px;
  background: #000;
  font-size: 15px;
  li {
    display: inline-block;
    margin-right: 4px;
    margin-left: 4px;
    color: #fff;
    font-size: 14px;
  }
}