.header {
    margin: 0 !important;
    background: #000;
    padding: 15px 15px 5px 15px;
    h5 {
      color: #05ddff;
      font-family: "Cubano", sans-serif;
    }
    .header-controls {
      color: #fff;
    }
    i {
      margin: 2px 4px;
      color:  #05ddff;
      padding: 2px;
    }

}