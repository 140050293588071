.paste-bin {
  background: #0a0c11;
  min-height: 100vh;
  display: flex;
  flex-flow: column wrap;
  .editor {
    display: flex;
    flex-flow: row wrap;
    min-height: 500px;
    .line-nos {
      background: #11101f;
      color: grey;
      display: flex;
      flex-flow: column wrap;
      span {
        padding-top: 5px;
        padding-right: 0.4rem;
        padding-left: 0.4rem;
        color: #62a9b9;
        font-size: 13px;
      }
    }
    textarea {
      background: #1a1930;
      flex: 1;
      border: 0;
      padding-left: 4px;
      color: white;
      margin-top: 10;
      padding-bottom: 0;
      line-height: 24.5px;
      overflow: hidden;
      &:focus {
        outline: none;
      }
      font-size: 13px;
    }
  }
}
