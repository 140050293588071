h5 {
    color: #0a0c11;
    font-family: 'Sofia Pro', sans-serif;
    font-weight: bold;
}
.card {
    &:hover {
        box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
        transform: translateY(-3px) translateZ(0);
    }
}
.card-header {
    border: none;
}
.card-footer {
    border: none;
}
.list-inline {
    display: flex;
}
.list-inline-item {
    font-size: 14px !important;
    flex: 1;
    text-align: center;
}
.python {
    color: #3572a5;
}
