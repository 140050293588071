/*SOCIAL SECTION */
ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
footer {
  .bottom-bar {
    .bottom-links {
      li.menu-item-has-children {
        .sub-menu {
          position: absolute;
          top: 30px;
          right: 0;
          width: 180px;
          background-color: transparent;
          border: 0;
          padding: 0;
          z-index: 9;
          display: none;
          text-align: right;
          &:before {
            content: "";
            background: transparent;
            width: 100%;
            height: 30px;
            top: -16px;
            right: 0;
            position: absolute;
          }
          li {
            margin: 0 !important;
            padding: 0;
            a {
              background: #000;
              display: block;
              padding: 15px 10px;
              font-weight: bold;
              color: #000;
              &:hover {
                background: #000;
                color: #000 !important;
              }
            }
            &:hover {
              a {
                &:after {
                  opacity: 0 !important;
                }
              }
            }
          }
        }
        .menu-item-has-children {
          >a {
            &::before {
              content: "\f104";
              font-family: "Sofia Pro", sans-serif, sans-serif;
              font-weight: bold;
              left: 20px;
              top: calc(50% - 7px);
              color: #000;
              position: absolute;
            }
          }
          .sub-menu {
            top: 0;
            right: 100%;
          }
        }
        &:hover {
          a {
            &:after {
              opacity: 0 !important;
            }
          }
          >.sub-menu {
            display: block;
          }
        }
      }
      display: flex;
      align-items: center;
      margin-left: auto;
      li {
        display: flex;
        align-items: center;
        position: relative;
        flex-shrink: 0;
        &:not(:last-of-type) {
          margin-right: 6px;
          &:after {
            content: "\f111";
            font: 900 5px "Sofia Pro", sans-serif;
            margin-left: 6px;
          }
        }
      }
    }
    display: flex;
    background: #0a0a0a;
    color: #fff;
    height: 100px;
    a {
      transition: color 0.6s ease-in-out;
      font: 1000 0.75em/1 "Sofia Pro", sans-serif;
      color: #fff;
      &:hover {
        color: #b4c3d2;
      }
    }
    p {
      font: 1000 0.75em/1 "Sofia Pro", sans-serif;
      color: #fff;
      line-height: 22px;
    }
    .container {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
    .news-container {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
    .copyright {
      p {
        a {
          margin-right: 4px;
          font-size: 14px;
        }
      }
    }
  }
  .sponsors {
    padding: 62px 0;
    background-color: transparent !important;
    border-bottom: 1px solid #141a21;
  }
  overflow: hidden;
  background-color: #000;
  font-family: 'Sofia Pro', sans-serif;
  display: block;
  .custom-logo-link {
    img {
      max-width: 240px;
      max-height: 60px;
      height: auto;
    }
  }
  .logo-img {
    img {
      max-height: 40px;
    }
  }
  .back-to-top {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    right: 0;
    top: -20px;
    background-color: #05ddff;
    cursor: pointer;
    color: #000;
    font-size: 26px;
    width: 40px;
    height: 40px;
  }
  .footer-content {
    display: flex;
    flex-wrap: wrap;
    margin: 60px 0;
    .footer-widget {
      &:first-child {
        margin-right: 108px;
      }
      &:not(:first-child) {
        margin-left: auto;
      }
      >ul {
        >li {
          &:not(:first-child) {
            margin-bottom: 20px;
          }
          &:last-of-type {
            margin-bottom: 0;
            margin-bottom: 0;
          }
          >a {
            font: 500 0.875em/1 "Sofia Pro", sans-serif, sans-serif;
            color: #636f7d;
            text-transform: uppercase;
            transition: color 0.8s ease-in-out;
            &:hover {
              color: #b6c0ca;
            }
          }
          ul.sub-menu {
            margin-top: 15px;
            padding-left: 15px;
            li {
              a {
                font: 700 0.75em/1 "Sofia Pro", sans-serif, sans-serif;
                color: #636f7d;
                text-transform: uppercase;
              }
              &:not(:last-child) {
                padding-bottom: 15px;
              }
            }
          }
          &:first-of-type {
            font: 1.375em/1 "Sofia Pro", sans-serif, sans-serif;
            color: #fff;
            margin-bottom: 44px;
          }
          &:not(:first-of-type) {
            margin-bottom: 30px;
          }
          i {
            color: #586370;
            margin-right: 14px;
            width: 14px;
          }
          .details {
            display: flex;
            flex-direction: column;
            justify-content: center;
            span {
              font: 700 1em/1 "Sofia Pro", sans-serif, sans-serif;
              color: #fff;
              margin-bottom: 4px;
            }
            a {
              font: 700 0.75em/1 "Sofia Pro", sans-serif, sans-serif;
              color: #636f7d;
              transition: color 0.6s ease-in-out;
              &:hover {
                color: rgba(255, 255, 255, 0.8);
              }
            }
          }
        }
      }
      >ul.menu {
        >li {
          &:not(:first-of-type) {
            line-height: 1.1;
            margin-bottom: 15px;
          }
        }
      }
    }
    .footer-widget.contact-links {
      max-width: 230px;
      margin-left: 0;
      ul {
        li {
          display: flex;
          align-items: center;
        }
      }
    }
    .footer-widget.footer-nav-links {
      max-width: 230px;
      li {
        &:not(:first-of-type) {
          padding-bottom: 10px;
          margin-bottom: 10px;
          border-bottom: 1px solid #12171c;
        }
        &:last-of-type {
          margin-bottom: 0;
          padding-bottom: 0;
          border: 0;
          a {
            font: 500 0.875em/1 "Sofia Pro", sans-serif, sans-serif;
            color: #fff;
            &:hover {
              opacity: 0.8;
            }
            i {
              color: #fff;
              margin-left: 6px;
              margin-bottom: 0;
            }
          }
        }
      }
      a {
        color: #636f7d;
        font: 500 0.875em "Sofia Pro", sans-serif, sans-serif;
        transition: color 0.6s ease-in-out;
        text-transform: none !important;
        &:hover {
          color: rgba(255, 255, 255, 0.8);
        }
      }
    }
  }
}
.section-header {
  font-family: 'Sofia Pro', sans-serif;
}
.social {
  display: flex;
  flex-direction: column;
  align-items: center;
  >ul {
    margin-top: 23px;
    display: flex;
    >li {
      &:not(:last-of-type) {
        margin-right: 25px;
      }
      a {
        color: #636f7d;
        font-size: 0.875em;
        transition: color 0.6s ease-in-out;
        &:hover {
          color: #46E9DC;
        }
        img {
          max-width: 210px;
          max-height: 32px;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  footer {
    .back-to-top {
      top: -65px;
    }
    .footer-content {
      justify-content: space-around;
      .footer-widget.contact-links {
        margin-left: 0;
        margin-bottom: 60px;
      }
      .footer-widget.footer-nav-links {
        margin-left: 0;
        margin-bottom: 60px;
      }
      .footer-widget {
        width: 40%;
        margin-left: 0;
      }
    }
    .bottom-bar {
      .container {
        flex-shrink: 0;
        justify-content: center;
        text-align: center;
      }
      .news-container {
        flex-shrink: 0;
        justify-content: center;
        text-align: center;
      }
      .copyright {
        display: block;
        width: 100%;
        flex-shrink: 0;
        margin-bottom: 20px;
      }
      .bottom-links {
        margin-left: 0;
        flex-shrink: 0;
      }
      align-items: center;
    }
  }
}
@media screen and (max-width: 646px) {
  footer {
    .back-to-top {
      right: 50%;
      transform: translateX(50%);
    }
    .footer-content {
      margin: 30px;
      .footer-widget {
        &:first-child {
          margin-bottom: 38px;
        }
        &:last-child {
          border-bottom: 0;
          padding-bottom: 0;
          margin-bottom: 0;
        }
        >ul {
          >li {
            i {
              margin-right: 0;
              margin-bottom: 8px;
            }
            flex-direction: column;
          }
        }
        display: flex;
        justify-content: center;
        width: 100% !important;
        margin-left: 0;
        margin-bottom: 30px;
        padding-bottom: 30px;
        border-bottom: 1px solid #141a21;
        text-align: center;
      }
      .footer-widget.contact-links {
        text-align: center;
        max-width: 100% !important;
        margin-bottom: 30px;
      }
      .footer-widget.footer-nav-links {
        text-align: center;
        max-width: 100% !important;
        margin-bottom: 30px;
        li {
          &:not(:first-of-type) {
            width: 230px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 992px) {
  footer {
    .footer-content {
      .footer-widget {
        &:first-child {
          width: 100%;
          margin: 0 auto 60px;
          padding-bottom: 62px;
          border-bottom: 1px solid #141a21;
          flex-shrink: 0;
        }
      }
    }
  }
  .social {
    margin-top: -5px;
  }
}